import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "¿Cómo protejo mis cuentas en línea?",
    answer:
      "Para proteger tus cuentas, usa contraseñas fuertes combinando mayúsculas, minúsculas, números y símbolos. Activa la autenticación multifactor (MFA) siempre que sea posible, y evita usar la misma contraseña para múltiples cuentas.",
  },
  {
    question: "¿Qué es la autenticación multifactor?",
    answer:
      "La autenticación multifactor (MFA) es una capa adicional de seguridad que requiere no solo una contraseña, sino también un segundo factor de verificación, como un código enviado a tu teléfono o una aplicación de autenticación.",
  },
  {
    question: "¿Qué debo hacer si soy víctima de ciberbullying?",
    answer:
      "Si eres víctima de ciberbullying, no respondas a los agresores. Guarda evidencia de los mensajes o acciones de acoso y bloquea al acosador. Informa el incidente a la plataforma en la que ocurrió y, si es necesario, busca ayuda legal o profesional.",
  },
  {
    question:
      "¿Qué impacto tiene el consumo excesivo de redes sociales en la salud mental y cómo puedo controlarlo?",
      answer: `El consumo excesivo de redes sociales puede afectar negativamente nuestra salud mental, generando ansiedad, estrés y comparaciones poco realistas con los demás.\n\nPara controlar su uso, es recomendable establecer límites de tiempo, desactivar notificaciones no esenciales y realizar actividades offline que promuevan el bienestar, como leer o hacer ejercicio.\n\n Si sientes que esto está afectando tu salud mental, busca ayuda profesional. Puedes encontrar recursos en [ITAA (Internet and Technology Addiction Association)](https://www.itaa.org/).`
      ,
  },
];

const CommunityScreen: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)", // Ajustar según la altura del header
        }}
      >
        <Box textAlign="center" py={5}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1", // Color principal de tu web
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              mb: 3,
            }}
          >
            Cada Aporte Cuenta
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, mb: 4 }}>
            ¡Tu apoyo es clave para promover la conciencia digital! Con cada
            contribución, ayudas a mantener este sitio web accesible y a
            fortalecer la seguridad en el entorno digital. <br />
            Cada aporte cuenta y puede hacer una gran diferencia.
            <br />
            <strong>¡Únete a nuestra causa y contribuye hoy mismo!</strong>
          </Typography>

          <CustomButton
            label="Únete"
            to="https://buymeacoffee.com/concienciadigital"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>

        {/* Acordeón de preguntas frecuentes */}
        <Box my={5}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                mb: 2,
                backgroundColor: "white", // Cambiado a fondo blanco
                borderRadius: "8px", // Bordes redondeados
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Sombra suave
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem", // Tamaño de fuente mayor
                    color: "#0D47A1", // Azul oscuro para las preguntas
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#37474F", // Gris oscuro para las respuestas
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default CommunityScreen;
