import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../src/components/Theme'; // Ajusta la ruta a tu archivo de tema
import CssBaseline from '@mui/material/CssBaseline'; // Para aplicar estilos globales y normalización
import HomeScreen from './screens/HomeScreen';
import EducacionScreen from './screens/EducationScreen';
import ArticulosScreen from './screens/ArticlesScreen';
import CommunityScreen  from './screens/CommunityScreen';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/nosotros" element={<HomeScreen/>} />
          <Route path="/educacion" element={<EducacionScreen />} />
          <Route path="/articulos" element={<ArticulosScreen />} />
         <Route path="/comunidad" element={<CommunityScreen />} /> 
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
